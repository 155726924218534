import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "@/templates/layout"
import { Site, SiteCard } from '@/components/site-card'

import Chart from "@/images/chart.inline.svg"

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"


const Index = (props: any) => {
  const { data } = props
  const author = data.author
  const avatar = author.avatar
  const name = `${author.name}`.trim()
  const links = author.links
  const sites = data.sites.nodes
  const url = avatar.url.replace("data.madcrypto.com", "www.madcrypto.com")

  const seo = {
    title: `Author - ${name} | Madcrypto`
  }

  return (
    <Layout {...props} seo={seo} title={name} className="no-footer-cta">
      <div className="header-author-page">
        <div className="container">
          <header className="site-header">
            <div className="section-content">
              <h1 itemProp="headline">
                <Link to={`/authors/${author.slug}/`}>
                  <img className="author-avatar" src={url} width={avatar.size} height={avatar.size} />
                </Link>
                <div>
                  <div>{name}</div>
                  <div className="links">
                    {links?.twitter && <a href={`https://twitter.com/${links.twitter}`} className="link">
                      <FontAwesomeIcon icon={faTwitter as any} />
                    </a>}
                    {links?.linkedin && <a href={links.linkedin} className="link">
                      <FontAwesomeIcon icon={faLinkedin as any} />
                    </a>}
                    {links?.website && <a href={links.website} className="link">
                      <FontAwesomeIcon icon={faGlobe} />
                    </a>}
                  </div>
                </div>
              </h1>
            </div>
          </header>
        </div>
        <Chart className="hero-unit-chart" />
      </div>

      <div className="author-description">
        <h3>About the author</h3>
        <p>
          {author.description}
        </p>
      </div>

      <h3>{name}'s latest articles</h3>
      <ol className="site-cards">
        {sites
          .sort((a: Site, b: Site) => a.menuOrder - b.menuOrder)
          .map((site: Site, i: number) => <SiteCard site={site} key={i} />)}
      </ol>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query ($slug: String, $language: String) {
    author: wpUser(slug: { eq: $slug }) {
      avatar {
        size
        url
      }
      slug
      name
      description
      links {
        linkedin
        twitter
        website
      }
    }
    sites: allWpSite(filter: {
      author: {node: {slug: {eq: $slug} } },
      locale: {locale: {eq: $language } },
    }) {
      nodes {
        ...SiteInformation
      }
    }
  }
`